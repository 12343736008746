import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FooterComponent } from "./footer/footer.component";
import { FooterRoutingModule } from "./footer-routing.module";

@NgModule({
    imports: [CommonModule, FooterRoutingModule, FooterComponent],
    exports: [FooterComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FooterModule {}
