<div class="flow-container pt-2">
  <div class="container pt-1">
    <div class="row">
      <div class="col-4">
        <img src="http://www.csrgs.org/assets/images/mission.jpg" alt="mission" />
      </div>
      <div class="col-8 mission">
        <p>
          The School is managed by
          <b>Jai Bharti Bahuudheyshiya Sevabhavi Pratishthan</b>. The school
          firmly believes in ancient Indian culture and acts for the molding of
          students with national charachter, with full respect and deep faith
          for their parents , teachers and the mother land.
        </p>
        <p>
          <b>Our MISSION</b> is to develop good health habit and build up basic
          skills among children, necessary for personal adjustment. We focus on
          devlopement of desirable social attitudes and manners to encourage
          healthy groups. <b>Our MOTO</b> is to create awareness among children
          about their social responsibilities and train them to be of service at
          all times in their life.
        </p>
        <p>
          To provide quality education which would make learning effective.
          Education should be such that the student should be motivated to
          pursue higher studies. All learning should be for the betterment of
          the learner and the society around them. Education received should
          make the student a humane individual, who excels not only in academics
          but would understand the finer aspects of life. The teaching and
          learning community should work together to achieve effective and
          achievable goals.
        </p>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-4">
        <img src="http://www.csrgs.org/assets/images/vision.jpg" alt="Vision" />
      </div>
      <div class="col-8 vision">
        <p>
          <b>Our Vision</b> is to develop well rounded, confident and
          responsible individuals who aspire to achieve their full potential. We
          encourage every child to strive towards excellence in every fields
          giving due considerations to welfare and needs of their fellow beings.
          We try to devlop effective communication at all levels within the
          school community comprising of administrators, staff, students and
          parents.
        </p>
        <p>
          We are looking forward to churn out the cream potential in children
          and gift society glittering gems <b> 'The Perfect Citizens' </b>.
        </p>
      </div>
    </div>
  </div>
  <div class="row front-images">
    <h2 style="left: 0; right: 0; width: 100%; text-align: center">
      Achievements
    </h2>
    <ngx-fast-marquee>
      <div>
        <img src="http://www.csrgs.org/assets/images/achivements/ach1.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/achivements/ach2.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/achivements/ach3.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/achivements/ach4.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/achivements/ach5.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/achivements/ach6.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/achivements/ach7.jpeg" class="frontImages" alt="Vision" />
      </div>
    </ngx-fast-marquee>
  </div>
  <div class="row front-images">
    <h2 style="left: 0; right: 0; width: 100%; text-align: center">Events</h2>

    <ngx-fast-marquee>
      <div>
        <img src="http://www.csrgs.org/assets/images/events/event1.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/events/event2.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/events/event3.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/events/event4.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/events/event5.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/events/event6.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/events/event7.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/events/event8.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/events/event9.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/events/event10.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/events/event11.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/events/event12.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/events/event13.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/events/event14.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/events/event15.jpeg" class="frontImages" alt="Vision" />
      </div>
      <div>
        <img src="http://www.csrgs.org/assets/images/events/event16.jpeg" class="frontImages" alt="Vision" />
      </div>
    </ngx-fast-marquee>
  </div>
</div>