<div class="flow-container border-bottom navigation-bar">
  <img src="assets/header.png" class="header" width="100%" />
  <div class="flow-container">
    <nav class="navbar navbar-expand-lg navbar-light">
      <button class="navbar-toggler d-lg-none" type="button" (click)="isCollapsed = !isCollapsed"
        [attr.aria-expanded]="!isCollapsed" aria-controls="navbarsExampleDefault" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarsExampleDefault" [ngbCollapse]="isCollapsed">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" style="margin-left:3rem">
            <a routerLink="/home" routerLinkActive="active" class="btn">Home</a>
          </li>
          <li ngbDropdown class="nav-item">
            <a href (click)="(false)" routerLinkActive="active" ngbDropdownToggle class="btn">About Us</a>
            <div ngbDropdownMenu>
              <button ngbDropdownItem routerLink="/aboutus">About CSRGS</button>

              <button ngbDropdownItem routerLink="/csrgs-infrastructure">
                Infrastructure
              </button>
              <button ngbDropdownItem routerLink="/our-school-management">
                Management
              </button>
              <!-- <button ngbDropdownItem routerLink="/staff-information">
                Staff Information
              </button> -->
              <button ngbDropdownItem routerLink="/student-information">
                Students Information
              </button>
              <!-- <button ngbDropdownItemo ruterLink="/mandatory-public-disclosure">
                Mandatory Public Disclosure
              </button> -->
              <button ngbDropdownItem routerLink="/annual-calendar">
                Annual Calendar
              </button>
              <button ngbDropdownItem routerLink="/socialConnect">
                Social Connect
              </button>
              <button ngbDropdownItem routerLink="/youtube-channel">
                Youtube Channel
              </button>
            </div>
          </li>
          <li class="nav-item">
            <a routerLink="/admission" routerLinkActive="active" class="btn">Admission</a>
          </li>
          <li class="nav-item">
            <a routerLink="/mandatory-public-disclosure" routerLinkActive="active" class="btn">Mandatory Public
              Disclosure</a>
          </li>

          <li class="nav-item">
            <a routerLink="/school-information" routerLinkActive="active" class="btn">School Data</a>
          </li>
          <li class="nav-item">
            <a routerLink="/achievements" routerLinkActive="active" class="btn">Achievements</a>
          </li>

          <li class="nav-item">
            <a routerLink="/our-gallery" routerLinkActive="active" class="btn">Gallery</a>
          </li>

          <li class="nav-item">
            <a routerLink="/facilities" routerLinkActive="active" class="btn">Facilities</a>
          </li>
          <li class="nav-item">
            <a routerLink="/contact-us" routerLinkActive="active" class="btn">Contact Us</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>