import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ScrollToTopRoutingModule } from "./scroll-to-top-routing.module";
import { ScrollToTopComponent } from "./scroll-to-top/scroll-to-top.component";


@NgModule({
    imports: [CommonModule, ScrollToTopRoutingModule, ScrollToTopComponent],
    exports: [ScrollToTopComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ScrollToTopModule {}
