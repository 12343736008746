<div class="container">
  <div class="row">
    <div class="col-12">
      <img
        src="http://www.csrgs.org/assets/management/Profiles/president.jpg"
        class="presidentImage"
        alt="President Image"
      />
      <p class="msg" style="font-size: 20px">
        <b>Message from President,</b> <br />
        Chhatrapati Sambhaji Raje Global School & Jr. College, Ambajogai. We
        strain every nerve to bring out innate potentials of students and offer
        them guidance to bloom wherever they are planted. All the diverse
        activities categorized under scholastic and co-scholastic areas have
        been placed diligently to provide adequate opportunities to all the
        budding stars of tomorrow to excel in the field of their choice and
        acumen. We aim to make our student physically fit, mentally alert and
        emotionally balanced. May god bless his choicest blessing on the
        students and the collective effort of the management and the staff to
        ensure on today that tomorrow will take care of them itself.
        <b style="float: right"> President - Adv. Madhav Jadhav </b>
      </p>
    </div>
  </div>
</div>
