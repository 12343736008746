import {
  enableProdMode,
  APP_INITIALIZER,
  importProvidersFrom,
} from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { routes } from "./app/app-routes";

import { environment } from "./environments/environment";
import { AppComponent } from "./app/app.component";
import { provideAnimations } from "@angular/platform-browser/animations";
import { ScrollToTopModule } from "./app/scroll-to-top/scroll-to-top.module";
import { FooterModule } from "./app/footer/footer.module";
import { HeaderModule } from "./app/header/header.module";
import { HomeModule } from "./app/home/home.module";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { EnvService } from "./app/env.service";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { AppRoutingModule } from "./app/app-routing.module";
// import { provideRouter } from '@angular/router';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      ReactiveFormsModule,
      HomeModule,
      HeaderModule,
      FooterModule,
      ScrollToTopModule,
      AppRoutingModule
    ),
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EnvService) => () => envService.init(),
      deps: [EnvService],
      multi: true,
    },
    provideAnimations(),
    // provideRouter(routes)
  ],
}).catch((err) => console.error(err));
