import { Component, OnInit } from "@angular/core";
import { CsrgsService } from "src/app/shared/csrgs.service";

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.scss"],
})
export class BannerComponent implements OnInit {
  myInterval = 3000;
  activeSlideIndex = 0;

  slides = [];

  constructor(private service: CsrgsService) {}
  albumId = 1;
  ngOnInit() {
    this.service.getAllBannerImages(this.albumId).subscribe((data: any[]) => {
      this.slides = data;
      console.log(this.slides);
    });
  }
}
