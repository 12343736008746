import {
  Component,
  ElementRef,
  OnInit,
  AfterViewInit,
  ViewChild,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { CsrgsService } from "src/app/shared/csrgs.service";

@Component({
  selector: "app-counterup",
  templateUrl: "./counterup.component.html",
  styleUrls: ["./counterup.component.css"],
})
export class CounterupComponent implements OnInit, AfterViewInit {
  title = "Animated Count";

  nums: Array<number> = [25, 76, 48];

  @ViewChild("oneItem", { static: true }) oneItem: any;
  @ViewChildren("count") count: QueryList<any>;

  constructor(private elRef: ElementRef, private service: CsrgsService) {}
  allCount: any = [];
  student: any = [];
  teachers: any = [];
  management: any = [];
  ngOnInit() {
    this.service.getCounts().then((data: any[]) => {
      this.allCount = data;
      // console.log(this.allCount);
      this.student = this.allCount[2].TABLE_ROWS;
      this.teachers = this.allCount[1].TABLE_ROWS;
      this.management = this.allCount[0].TABLE_ROWS;
      // console.log(this.student, this.teachers, this.management);
    });
  }

  ngAfterViewInit() {
    this.count.changes.subscribe((countElements) => {
      if (countElements.length) {
        this.animateCount();
      }
    });
  }
  single: any;

  animateCount() {
    this.single = this.oneItem.nativeElement.innerHTML;

    this.counterFunc(this.single, this.oneItem, 7000);

    this.count.forEach((item) => {
      this.counterFunc(item.nativeElement.innerHTML, item, 2000);
    });
  }

  counterFunc(end: number, element: any, duration: number) {
    let range, current: number, step, timer;

    range = end - 0;
    current = 0;
    step = Math.abs(Math.floor(duration / range));

    timer = setInterval(() => {
      current += 1;
      element.nativeElement.textContent = current;
      if (current == end) {
        clearInterval(timer);
      }
    }, step);
  }
}
