import { Component, OnInit } from "@angular/core";
import { CsrgsService } from "src/app/shared/csrgs.service";

@Component({
  selector: "app-open-admission",
  templateUrl: "./open-admission.component.html",
  styleUrls: ["./open-admission.component.css"],
})
export class OpenAdmissionComponent implements OnInit {
  myInterval = 5000;
  activeSlideIndex = 0;

  currentYear: number = new Date().getFullYear();
  nextYear: number = new Date().getFullYear() + 1;

  slides = [];

  constructor(private service: CsrgsService) { }
  albumId = 2;
  ngOnInit() {
    // this.service.getAllBannerImages(this.albumId).subscribe((data: any[]) => {
    //   this.slides = data;
    //   console.log(this.slides);
    // });
  }
}
