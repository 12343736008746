import { Component } from "@angular/core";
import { NavbarComponent } from "./header/navbar/navbar.component";
import { FooterComponent } from "./footer/footer/footer.component";
import { RouterOutlet } from "@angular/router";
import { ScrollToTopComponent } from "./scroll-to-top/scroll-to-top/scroll-to-top.component";

@Component({
  selector: "app-root",
  standalone: true,
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  imports: [
    NavbarComponent,
    FooterComponent,
    RouterOutlet,
    ScrollToTopComponent,
  ],
})
export class AppComponent {
  title = "csrgs";
}
