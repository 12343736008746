<div class="container objectiveText pt-2">
  <div class="row pt-2 pb-2">
    <div class="col-12 introduction">
      <h2>Introduction</h2>
      <p>
        Chhatrapati Sambhaji Raje Global School & Jr. College is the CBSE English Medium school.
        <b> Chhatrapati Sambhaji Raje Global School & Jr. College </b> has been established in 2017 by Jai Bharti
        Bahuuddeshiya Sevabhavi Pratishthan's Ghatnandur.
        CSRGS conduct classes from 1st to 10th standard. The students of this school are taught to think globally, by
        providing them quality education, emphasizing on academics, sports and cultural development. To carry out this
        task more effectively and efficiently we have a bunch of committed and responsible staff. The school building
        itself is an architectural marvel, built up keeping in mind the comfort of students.
      </p>
    </div>
  </div>
  <div class="row" style="height: 52%">
    <div class="col-12">
      <img src="http://www.csrgs.org/assets/images/aimsObjective.jpg" alt="" class="objectiveImage" />
    </div>
  </div>
  <div class="row aims">
    <div class="col-12">
      <h2>Aims and Objectives</h2>
      <p>Aims and Objectives of the school are :-</p>
      <ul>
        <li>
          Develop good health-habits and buildup basic skills among children,
          necessary for personal adjustments.
        </li>
        <li>
          Develop desirable social attitudes and manners to encourage healthy
          group.
        </li>
        <li>
          Participation and make children sensitive to the rights and privileges
          of others.
        </li>
        <li>
          Help them to develop a cheering attitude and sense of appreciation
          which will benefit the society.
        </li>
        <li>
          Create awareness about their social responsibilities and train them to
          be of service at all times in their life.
        </li>
        <li>
          Make them realize the value of their freedom and use it judiciously.
        </li>
        <li>
          Enable them to understand themselves better and to instill confidence
          and make them useful in nation building.
        </li>
        <li>
          Foster among them a positive attitude towards problem solving in
          academic situations.
        </li>
        <li>
          Encourage them to strive towards excellence in every field giving due
          consideration to the welfare and needs of their fellow beings.
        </li>
        <li>
          Develop effective communication at all levels within the school
          community staff, students and parents.
        </li>
      </ul>
    </div>
  </div>
</div>