<section class="footers pt-2 pb-3 border">
  <div class="container pt-3">
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-4 footers-one">
        <div class="footers-logo">
          <img src="assets/logo.png" alt="Logo" class="logo" />
        </div>
        <div class="footers-info mt-3">
          <p>
            "Empowering students to create solutions for tomorrow's challenges"
          </p>
        </div>
        <div class="social-icons">
          <a href="https://www.facebook.com/"
            ><i id="social-fb" class="fa fa-facebook-square social"></i
          ></a>
          <a href="https://twitter.com/"
            ><i id="social-tw" class="fa fa-twitter-square social"></i
          ></a>
          <a href="https://linkedin.com/"
            ><i id="social-li" class="fa fa-linkedin-square social"></i
          ></a>
          <a href="https://plus.google.com/"
            ><i id="social-gp" class="fa fa-google-plus-square social"></i
          ></a>
          <a href="test@gmail.com"
            ><i id="social-em" class="fa fa-envelope-square social"></i
          ></a>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-2 footers-two">
        <h5>Essentials</h5>
        <ul class="list-unstyled">
          <li><a routerLink="/aboutus">About Us</a></li>
          <li><a routerLink="/studyPattern">Study Patterns</a></li>
          <li><a routerLink="/admission">Admission Process</a></li>
          <li>
            <a routerLink="/rules-of-deciplines">Rules of Discipilines</a>
          </li>
        </ul>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-2 footers-three">
        <h5>Information</h5>
        <ul class="list-unstyled">
          <li><a routerLink="/facilities">Our Facilities</a></li>
          <li><a routerLink="/socialConnect">Social Blogs</a></li>
          <li><a routerLink="/our-gallery">Our Gallery</a></li>
          <li><a routerLink="/student-information">Student Information</a></li>
        </ul>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-2 footers-four">
        <h5>Explore</h5>
        <ul class="list-unstyled">
          <li><a routerLink="/results">Results</a></li>
          <li><a routerLink="/upcomingEvents">Upcoming Events</a></li>
          <li><a routerLink="/aboutus">Our Misson</a></li>
          <li><a routerLink="/aboutus">Our Vision</a></li>
        </ul>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-2 footers-five">
        <h5>Organization</h5>
        <ul class="list-unstyled">
          <li><a routerLink="/our-school-management">School Management</a></li>
          <li><a routerLink="/departments">Departments</a></li>
          <li><a routerLink="/faculties">Faculties</a></li>
          <li><a routerLink="/contact-us">Contact Us</a></li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!-- <section class="disclaimer bg-light border">
  <div class="container">
    <div class="row ">
      <div class="col-md-12 py-2">
        <small>
          Disclaimer: Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Aspernatur ut voluptates beatae enim ad sit at labore optio fugiat
          quibusdam. Laboriosam, a nulla. Provident explicabo molestiae
          assumenda accusamus autem excepturi!
        </small>
      </div>
    </div>
  </div>
</section> -->

<section class="copyright border">
  <div class="container">
    <div class="row text-center">
      <div class="col-md-12 pt-3">
        <p>
          © {{ currentYear }}
          <a
            href="http://www.csrg.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chhatrapati Sambhaji Raje Global School & Jr. College, Ambajogai.</a
          >
        </p>
      </div>
    </div>
  </div>
</section>

<!-- <button *ngIf="isShow" (click)="gotoTop()" class="back-to-top">
        <i class="fa fa-caret-up"></i>
      </button> -->
