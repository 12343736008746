import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
// import { NgxSpinnerService } from "ngx-spinner";
import { CsrgsService } from "src/app/shared/csrgs.service";

@Component({
  selector: "app-user-home",
  templateUrl: "./user-home.component.html",
  styleUrls: ["./user-home.component.css"],
})
export class UserHomeComponent implements OnInit {
  public constructor(
    private titleService: Title,
    // private spinner: NgxSpinnerService,
    private service: CsrgsService
  ) {}

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit() {
    this.setTitle("Home - CSRGS");
    // this.spinner.show();
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 2000);
    // this.loadTextData();
  }
  allContents: any = [];

  loadTextData() {
    this.service.getAllContents().subscribe((data) => {
      this.allContents = data;
      console.log(this.allContents);
      localStorage.setItem("textData", this.allContents);
    });
  }
}
