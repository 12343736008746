import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NgxSpinnerModule } from "ngx-spinner";
const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  {
    path: "home",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "our-gallery",
    loadChildren: () =>
      import("./gallery/gallery.module").then((m) => m.GalleryModule),
  },
  {
    path: "admission",
    loadChildren: () =>
      import("./admission/admission.module").then((m) => m.AdmissionModule),
  },
  {
    path: "register-new-admission",
    loadChildren: () =>
      import("./admission-register/admission-register.module").then(
        (m) => m.AdmissionRegisterModule
      ),
  },
  {
    path: "student-information",
    loadChildren: () =>
      import("./students/students.module").then((m) => m.StudentsModule),
  },
  {
    path: "staff-information",
    loadChildren: () =>
      import("./staff/staff.module").then((m) => m.StaffModule),
  },
  {
    path: "contact-us",
    loadChildren: () =>
      import("./contactus/contactus.module").then((m) => m.ContactusModule),
  },
  {
    path: "annual-calendar",
    loadChildren: () =>
      import("./calendar/calendar.module").then((m) => m.CalendarModule),
  },
  {
    path: "facilities",
    loadChildren: () =>
      import("./facilities/facilities.module").then((m) => m.FacilitiesModule),
  },
  {
    path: "mandatory-public-disclosure",
    loadChildren: () =>
      import("./mandatory-pd/mandatory-pd.module").then(
        (m) => m.MandatoryPDModule
      ),
  },
  {
    path: "youtube-channel",
    loadChildren: () =>
      import("./youtube/youtube.module").then((m) => m.YoutubeModule),
  },
  {
    path: "socialConnect",
    loadChildren: () =>
      import("./social-connect/social-connect.module").then(
        (m) => m.SocialConnectModule
      ),
  },
  {
    path: "our-school-management",
    loadChildren: () =>
      import("./management/management.module").then((m) => m.ManagementModule),
  },
  {
    path: "csrgs-infrastructure",
    loadChildren: () =>
      import("./infrastructure/infrastructure.module").then(
        (m) => m.InfrastructureModule
      ),
  },
  {
    path: "aboutus",
    loadChildren: () =>
      import("./aboutus/aboutus.module").then((m) => m.AboutusModule),
  },
  {
    path: "achievements",
    loadChildren: () =>
      import("./achievements/achievements.module").then(
        (m) => m.AchievementsModule
      ),
  },
  {
    path: "school-information",
    loadChildren: () =>
      import("./school-data/school-data.module").then(
        (m) => m.SchoolDataModule
      ),
  },
  {
    path: "rules-of-deciplines",
    loadChildren: () =>
      import("./rules/rules.module").then((m) => m.RulesModule),
  },
  {
    path: "details-of-curriculum",
    loadChildren: () =>
      import("./curriculum-details/curriculum-details.module").then(
        (m) => m.CurriculumDetailsModule
      ),
  },
  {
    path: "leaving-certificate-info",
    loadChildren: () =>
      import("./leaving-certificate/leaving-certificate.module").then(
        (m) => m.LeavingCertificateModule
      ),
  },
  {
    path: "school-fee-structure",
    loadChildren: () =>
      import("./fee-structure/fee-structure.module").then(
        (m) => m.FeeStructureModule
      ),
  },
  {
    path: "school-annual-report",
    loadChildren: () =>
      import("./annual-report/annual-report.module").then(
        (m) => m.AnnualReportModule
      ),
  },
  {
    path: "school-self-declaration",
    loadChildren: () =>
      import("./principal-declaration/principal-declaration.module").then(
        (m) => m.PrincipalDeclarationModule
      ),
  },
  {
    path: "list-of-books-class-wise",
    loadChildren: () =>
      import("./prescribed-books/prescribed-books.module").then(
        (m) => m.PrescribedBooksModule
      ),
  },
  {
    path: "school-circular",
    loadChildren: () =>
      import("./school-circular/school-circular.module").then(
        (m) => m.SchoolCircularModule
      ),
  },
  {
    path: "teachers-training",
    loadChildren: () =>
      import("./teachers-training/teachers-training.module").then(
        (m) => m.TeachersTrainingModule
      ),
  },
  {
    path: "csrgs-self-affidavit",
    loadChildren: () =>
      import("./self-affidavit/self-affidavit.module").then(
        (m) => m.SelfAffidavitModule
      ),
  },
  {
    path: "our-achievements-innovations",
    loadChildren: () =>
      import("./other-achievements/other-achievements.module").then(
        (m) => m.OtherAchievementsModule
      ),
  },

  {
    path: "**",
    loadChildren: () =>
      import("./notfound/notfound.module").then((m) => m.NotfoundModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), NgxSpinnerModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
