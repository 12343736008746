import { Component, OnInit } from "@angular/core";
import { CsrgsService } from "src/app/shared/csrgs.service";

@Component({
  selector: "app-events",
  templateUrl: "./events.component.html",
  styleUrls: ["./events.component.css"],
})
export class EventsComponent implements OnInit {
  allEvents: any = [];

  constructor() // private service: CsrgsService
  {}

  ngOnInit() {
    // this.service.getAllEvents().subscribe(data => {
    //   this.allEvents = data;
    //   console.log(this.allEvents);
    // });
  }
}
