@if (data.length > 0) {
  <div>
    <div class="row" style="margin-right: 0; margin-left: 0">
      <div class="col-12" style="padding-right: 0; padding-left: 0">
        <h3
        style="
          text-align: center;
          background: yellow;
          color: deeppink;
          margin-top: 5px;
        "
          >
          Upcoming Birthdays
        </h3>
      </div>
    </div>
    <div class="row" style="margin-right: 0; margin-left: 0">
      @for (class of data; track class; let index = $index) {
        <div class="col-3 gfg">
          <img src="{{ class.photoURL }}" alt="" srcset="" />
          <p class="second-txt">
            {{ class.firstname }} {{ class.lastname }} <br />{{ class.dob }}
          </p>
        </div>
      }
    </div>
  </div>
}
