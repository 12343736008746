<div class="flow-container">
  <div class="row pt-2 pb-2">
    <div class="col-12">
      <p class="text-center">
        <button type="button" class="btn btn-lg btn-outline-success" routerLink="/register-new-admission">
          Click here for admission registration
        </button>
      </p>
      <h3 class="text-center">
        Admissions open for academic year {{ currentYear }} - {{ nextYear }}
      </h3>
    </div>
    <div class="col-12">
      <!-- <carousel [interval]="myInterval" [(activeSlide)]="activeSlideIndex">
        @for (slide of slides; track slide; let index = $index) {
          <slide>
            <img
              [src]="slide.photoURL"
              alt="image slide"
              class="imageslider"
              style="display: block"
              />
            </slide>
          }
        </carousel> -->
      <div class="row front-images">
        <ngx-fast-marquee>
          <div>
            <img src="http://www.csrgs.org/assets/images/admission/admission1.jpeg" class="frontImages"
              alt="admission" />
          </div>
          <div>
            <img src="http://www.csrgs.org/assets/images/admission/admission2.jpeg" class="frontImages"
              alt="admission" />
          </div>
          <div>
            <img src="http://www.csrgs.org/assets/images/admission/admission3.jpeg" class="frontImages"
              alt="admission" />
          </div>
          <div>
            <img src="http://www.csrgs.org/assets/images/admission/admission4.jpeg" class="frontImages"
              alt="admission" />
          </div>
          <div>
            <img src="http://www.csrgs.org/assets/images/admission/admission5.jpeg" class="frontImages"
              alt="admission" />
          </div>
          <div>
            <img src="http://www.csrgs.org/assets/images/admission/admission6.jpeg" class="frontImages"
              alt="admission" />
          </div>
          <div>
            <img src="http://www.csrgs.org/assets/images/admission/admission7.jpeg" class="frontImages"
              alt="admission" />
          </div>
          <div>
            <img src="http://www.csrgs.org/assets/images/admission/admission8.jpeg" class="frontImages"
              alt="admission" />
          </div>
        </ngx-fast-marquee>
      </div>
    </div>
  </div>
</div>

<div class="container openAdmissionText">
  <hr />
  <div class="row">
    <div class="col-6 abc">
      <h3>FEATURES</h3>
      <ul class="list-group">
        <li class="list-group-item list-group-item-success section1">
          Science, Maths and English Olympaid. <br />
          Jr. IAS exams, MTS
        </li>
        <li class="list-group-item list-group-item-success section1">
          NEET, JEE foundations course from 6th std.
        </li>
        <li class="list-group-item list-group-item-success section1">
          Field trips, picnics and project work
        </li>
        <li class="list-group-item list-group-item-success section1">
          Karate and Yoga
        </li>
        <li class="list-group-item list-group-item-success section1">
          Celebration of cultural programme
        </li>
        <li class="list-group-item list-group-item-success section1">
          Maximum 35 students per class
        </li>
        <li class="list-group-item list-group-item-success section1">
          E-Learning
        </li>
      </ul>
    </div>
    <div class="col-6 abc">
      <h3>THE INFRASTRUCTURE</h3>
      <ul class="list-group">
        <li class="list-group-item list-group-item-success section2">
          CSRGS school is located 400 meter away from Tathagat Chowk, Pokhari
          Road, Jogaiwadi, Ambajogai. It is being occupied on more than 2.5
          acres land.
        </li>
        <li class="list-group-item list-group-item-success section2">
          It has big school building with all amenities.
        </li>
        <li class="list-group-item list-group-item-success section2">
          The academic included Smart class, Computer lab, Maths lab, Science
          lab, Audio visual voom and Library.
        </li>
        <li class="list-group-item list-group-item-success section2">
          It has huge open play ground for various indoors & outdoor sports and
          games.
        </li>
      </ul>
    </div>
  </div>
  <hr />
  <div class="row pt-2 mb-2">
    <div class="col-6 abc">
      <h3>EXTRA ACTIVITIES</h3>
      <ul class="list-group">
        <li class="list-group-item list-group-item-success section1">
          Abacus [Mental Maths] * Art Craft * Dance
        </li>
        <li class="list-group-item list-group-item-success section1">
          Archery * Fencing * Table tennis * Badminton - Special Coach
        </li>
      </ul>
    </div>
    <!-- <div class="col-6">
      <img src="assets/bannerImages/transport-map.jpg" class="transport" alt="">
    </div> -->
  </div>

  <!-- <div class="row pt-2">
  <div class="col-12">
    <img src="assets/bannerImages/transport-map.jpg" class="transport" alt="">
  </div>
</div> -->
</div>