<section id="team" class="pb-5">
  <div class="container">
    <hr />
    <div class="heading">
      <h1 class="font-weight-light text-center">School Board Directors</h1>
    </div>
    <hr />

    <div class="row">
      @for (class of allDirectorInfo; track class; let index = $index) {
        <div
          class="col-md-6 p-2"
          >
          <div class="row">
            <div class="col-md-6 img">
              <img src="{{ class.photoURL }}" alt="" class="img-rounded" />
            </div>
            <div class="col-md-6 details">
              <blockquote>
                <h5>{{ class.name }}</h5>
                <p>
                  {{ class.position }} <br />
                  {{ class.phone }} <br />
                  {{ class.address }} <br />
                  <a href="mailto: ">{{ class.email }}</a> <br />
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</section>
