<!--Scroll to top-->
<div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
  <!-- <button (click)="scrollToTop()">
      <i class="fa fa-chevron-up"></i>
  </button> -->
  <div class="example-button-container" (click)="scrollToTop()">
      <button mat-mini-fab style="color: blue;" aria aria-label="Go to top">
        <!-- <mat-icon>menu</mat-icon> -->
        <span class="material-icons" aria-readonly="false">
          arrow_circle_up
          </span>
      </button>
    </div>
</div>

