import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UserHomeComponent } from "./user-home/user-home.component";
import { HomeRoutingModule } from "./home-routing.module";
import { BannerComponent } from "./banner/banner.component";
import { DirectorsComponent } from "./directors/directors.component";
import { EventsComponent } from "./events/events.component";
import { ObjectiveComponent } from "./objective/objective.component";
import { OpenAdmissionComponent } from "./open-admission/open-admission.component";
import { OurMotoComponent } from "./our-moto/our-moto.component";
import { SocialviewComponent } from "./socialview/socialview.component";
import { CounterupComponent } from "./counterup/counterup.component";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { AnimatedDigitsComponent } from "./animated-digits/animated-digits.component";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { HttpClientModule } from "@angular/common/http";
import { BirthdayComponent } from "./birthday/birthday.component";
import { NgxFastMarqueeModule } from "ngx-fast-marquee";



@NgModule({
  imports: [CommonModule, HomeRoutingModule, HttpClientModule, CarouselModule, NgxFastMarqueeModule],
  declarations: [
    UserHomeComponent,
    BannerComponent,
    DirectorsComponent,
    EventsComponent,
    ObjectiveComponent,
    OpenAdmissionComponent,
    OurMotoComponent,
    SocialviewComponent,
    CounterupComponent,
    AnimatedDigitsComponent,
    BirthdayComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  // providers: [NgxSpinnerService],
  exports: [ObjectiveComponent, OpenAdmissionComponent, OurMotoComponent],
})
export class HomeModule { }
