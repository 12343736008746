<div class="flow-container">
  <div class="counterup">
    <table class="table">
      <tr>
        <td>
          <a routerLink="/student-information" target="_blank">
            <app-animated-digits
              [digit]="student"
              [duration]="5000"
            ></app-animated-digits>
            <h2 class="text-center">Students</h2>
          </a>
        </td>
        <td>
          <a routerLink="/contact-us" target="_blank">
            <app-animated-digits
              [digit]="management"
              [duration]="5000"
            ></app-animated-digits>
            <h2 class="text-center">Management</h2></a
          >
        </td>
        <td>
          <a routerLink="/staff-information" target="_blank">
            <app-animated-digits
              [digit]="teachers"
              [duration]="5000"
            ></app-animated-digits>
            <h2 class="text-center">Staff</h2></a
          >
        </td>
      </tr>
    </table>
  </div>
</div>
