import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderRoutingModule } from "./header-routing.module";
import { NavbarComponent } from "./navbar/navbar.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    imports: [CommonModule, NgbModule, HeaderRoutingModule, NavbarComponent],
    exports: [NavbarComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HeaderModule {}
