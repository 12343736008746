import { Component, OnInit } from "@angular/core";
import { CsrgsService } from "src/app/shared/csrgs.service";

@Component({
  selector: "app-birthday",
  templateUrl: "./birthday.component.html",
  styleUrls: ["./birthday.component.css"],
})
export class BirthdayComponent implements OnInit {
  data: any = [];
  constructor(private service: CsrgsService) {}

  ngOnInit() {
    this.service.getBirthdayInfo().then((data: any[]) => {
      this.data = data;
      // this.data.length = 0;
      console.log(this.data);
    });
  }
}
