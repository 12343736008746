import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { EnvService } from "../env.service";

@Injectable({
  providedIn: "root",
})
export class CsrgsService {
  apiKey: string = "AIzaSyBVpHLC-q-U-F6gePe4FN5fMSW98MlSqME";
  errorData: {};
  // public url = "http://localhost:81/uat/api-csrgs/";
  public url = "http://www.csrgs.org/api-csrgs/";

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  respType = { responseType: "text" };

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    this.errorData = {
      errorTitle: "Oops! Request for document failed",
      errorDesc: "Something bad happened. Please try again later.",
    };
    return throwError(this.errorData);
  }

  //public url: any;

  constructor(public http: HttpClient, private envService: EnvService) {
    // this.url = this.envService.apiUrl;
  }

  getVideosForChanel(channel, maxResults): Observable<Object> {
    let url =
      "https://www.googleapis.com/youtube/v3/search?key=" +
      this.apiKey +
      "&channelId=" +
      channel +
      "&order=date&part=snippet &type=video,id&maxResults=" +
      maxResults;
    return this.http.get(url).pipe(
      map((res) => {
        return res;
      })
    );
  }
  registerInquiries(name, email, phone, message) {
    return this.http
      .post<any>(this.url + "inquiries_register.php", {
        name,
        email,
        phone,
        message,
      })
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  registerAdmission(
    studentName,
    motherName,
    regclass,
    phone,
    dob,
    gender,
    previousSchool,
    address
  ) {
    // console.log(
    //   "from service",
    //   studentName,
    //   motherName,
    //   regclass,
    //   phone,
    //   dob,
    //   gender,
    //   previousSchool,
    //   address
    // );
    return this.http
      .post<any>(this.url + "admission_register.php", {
        studentName,
        motherName,
        regclass,
        phone,
        dob,
        gender,
        previousSchool,
        address,
      })
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  getAllEvents() {
    return this.http.get(this.url + "getAllPublishedEvents.php");
  }

  getAllClassInfo() {
    return this.http.get(this.url + "getAllClassInfo.php").toPromise();
  }

  getStudentsSum() {
    return this.http.get(this.url + "getStudentsSum.php").toPromise();
  }

  getAllContents() {
    //console.log("from service");
    return this.http.get(this.url + "getAllContents.php", {
      responseType: "text",
    });
  }

  getAllStaffInfo() {
    return this.http.get(this.url + "getAllStaffInfo.php").toPromise();
  }
  getAllDirectorInfo() {
    return this.http.get(this.url + "getAllDirectorsInfo.php").toPromise();
  }
  getAllManagementInfo() {
    return this.http.get(this.url + "getAllManagementInfo.php").toPromise();
  }
  getBirthdayInfo() {
    return this.http.get(this.url + "getBirthdayInfo.php").toPromise();
  }
  getCounts() {
    return this.http.get(this.url + "getAllRecordsTableWise.php").toPromise();
  }

  getAllBannerImages(id) {
    return this.http.get(this.url + "getImagesByAlbumId.php?id=" + id);
  }

  getAllAlbums() {
    return this.http.get(this.url + "getAllGalleryAlbums.php");
  }
}
