<!-- <ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#21e0bd"
  type="ball-spin-clockwise"
  [fullScreen]="true"
>
  <p style="color: #21e0bd">Loading...</p>
</ngx-spinner> -->

<app-banner></app-banner>
<div class="container">
  <!-- <div class="row">
    <div class="col-12">
      <app-birthday></app-birthday>
    </div>
  </div> -->
  <div class="row">
    <div class="col-7">
      <app-objective></app-objective>
    </div>
    <div class="col-5 pt-2">
      <app-events></app-events>
    </div>
  </div>
</div>
<app-open-admission></app-open-admission>
<app-our-moto></app-our-moto>

<!-- <app-directors></app-directors> -->

<hr />
<app-counterup></app-counterup>
<app-socialview></app-socialview>