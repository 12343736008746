import { Component, OnInit } from "@angular/core";
import { CsrgsService } from "src/app/shared/csrgs.service";

@Component({
  selector: "app-directors",
  templateUrl: "./directors.component.html",
  styleUrls: ["./directors.component.css"],
})
export class DirectorsComponent implements OnInit {
  allDirectorInfo: any = [];

  constructor(private service: CsrgsService) {}

  ngOnInit() {
    this.service.getAllDirectorInfo().then((data: any[]) => {
      this.allDirectorInfo = data;
      console.log(this.allDirectorInfo);
    });
  }
}
