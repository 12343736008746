<!-- <div class="shadow-lg rounded social-view">
  <div class="web-share-btn">
    <div id="social-links">
      <ul class="mobilesocialshare">
        <li class="mt-3">
          <a
            href="https://www.facebook.com/"
            class="social-button facebook-btn-share"
            target="_blank"
            ><span class="fa fa-facebook-square"></span
          ></a>
        </li>
        <li class="mt-2">
          <a
            href="https://twitter.com/"
            class="social-button twitter-btn-share"
            target="_blank"
            ><span class="fa fa-twitter-square"></span
          ></a>
        </li>
        <li class="mt-2">
          <a
            href="https://www.instagram.com/"
            class="social-button google-btn-share"
            target="_blank"
            ><span class="fa fa-instagram"></span
          ></a>
        </li>
      </ul>
    </div>
  </div>
</div> -->

<div class="icon-bar social-view">
  <a
    href="https://m.facebook.com/ChhatrapatiSambhajirajeGlobalSchoolAmbajogai/about/?ref=page_internal&mt_nav=0&_rdr"
    target="_blank"
    class="facebook"
    ><i class="fa fa-facebook"></i
  ></a>
  <a href="https://twitter.com/CsrgsA" target="_blank" class="twitter"
    ><i class="fa fa-twitter"></i
  ></a>
  <!-- <a href="https://www.instagram.com/" target="_blank" class="google"><i class="fa fa-instagram"></i></a> 
  <a href="https://www.facebook.com/" target="_blank" class="linkedin"><i class="fa fa-linkedin"></i></a> -->
  <a
    href="https://m.youtube.com/channel/UCDKrbJ2W7At2B5zJ6lx6PvQ/featured"
    target="_blank"
    class="youtube"
    ><i class="fa fa-youtube"></i
  ></a>
</div>
