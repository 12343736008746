<div class="container">
  <div class="row">
    <div class="col-12 m-0 p-0">
      <carousel [interval]="myInterval" [(activeSlide)]="activeSlideIndex">
        @for (slide of slides; track slide; let index = $index) {
          <slide>
            <img
              [src]="slide.photoURL"
              alt="image slide"
              class="imageslider"
              style="display: block"
              />
            </slide>
          }
        </carousel>
      </div>
    </div>
  </div>
